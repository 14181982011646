.login-component {
  --login-background: url("../../assets/images/background-haven-steel.jpg");
  --login-button-color: var(--main-color);
  --login-button-color-shaded: var(--main-color-shaded);
  --login-panel-color: var(--panel-color);
}

.login-container {
  padding: 20px;
  border-radius: 5px;
  background: white;
  opacity: 0.85;
}

.application-name {
  width: 100%;
  font-size: 150%;
  font-weight: 600;
  color: white;
}

.login-content-container {
  display: flex;
  justify-content: center;
}

.login-title-container {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-bottom: 20px;
}

.login-title-container a {
  text-decoration: none;
}

.login-title {
  font-size: 24px;
  font-weight: 600;
  color: darkgray;
  cursor: pointer;
}

.login-title:hover {
  color: var(--main-color-shaded);
  transition: color 200ms ease-in-out;
}

.login-title.active {
  color: black;
  text-decoration: underline;
}

.button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.space-up {
  margin-bottom: 0;
  margin-top: 20px;
}

.input-columns {
  display: flex;
  flex-direction: row;
  gap: 25px;
}

.login-component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  height: fit-content;
  position: relative;
  text-align: center;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent, rgba(0, 0, 0, 0.8)), var(--login-background);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: "pt-sans", sans-serif;
}

.login-component input:active {
  border: none;
}

.login-component .ant-input,
.login-component .ant-select-selector {
  border: none !important;
  border-bottom: 1px solid gray !important;
  text-align: left;
  padding: 4px;
}

.login-component .ant-form-item-label > label {
  font-size: 18px;
  font-weight: 500;
  margin-top: 15px;
}

.login-component .ant-form-item-explain,
.login-component.ant-form-item-extra {
  text-align: start;
}

.login-component .ant-form-item-has-error.ant-form-item-has-feedback,
.login-component .ant-form-item-children-icon {
  top: 13px;
}

.login-component .ant-btn {
  height: auto;
  font-size: 16px;
}

.login-component input:-internal-autofill-selected {
  background-color: unset;
}

.login-component .ant-btn.primary-btn {
  padding: 6px 30px;
  background-color: var(--login-button-color);
  color: #fff;
  border: none;
}

.login-component .ant-btn.primary-btn:hover {
  background-color: var(--login-button-color-shaded);
}

.login-component .ant-btn.colorless-btn {
  padding: 6px 30px;
  background-color: transparent;
}

.login-component .ant-btn.borderless-btn {
  padding-top: 6px;
  padding-bottom: 6px;
  background-color: transparent;
  font-weight: 600;
  border: none;
}
