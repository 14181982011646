.numpad-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.numpad-component > .ant-btn {
    font-size: calc(18px + min(1vw, 1vh));
    text-transform: uppercase;
    height: 100%;
    margin-top: 50px;
    padding: 5px 35px;
    border-color: var(--secondary-color);
    color: var(--secondary-color);
}

.numpad-component .numpad-grid-container {
    display: flex;
    justify-content: center;
}

.numpad-component .numpad-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: calc(10px + min(1vw, 1vh));
    justify-items: center;
}

.numpad-component .numpad-display {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    font-size: calc(18px + 2 * min(1vw, 1vh));
    min-height: 63px;
    margin-bottom: 25px;
}

.numpad-grid .ant-btn {
    width: calc(50px + 4*min(1vw, 1vh));
    height: calc(50px + 4*min(1vw, 1vh));
    font-size: calc(24px + min(1vw, 1vh));
    border-radius: 50%;
}

.numpad-component .arrow-left-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
}

.numpad-component .numpad-message {
    color: red;
    margin-bottom: 25px;
    line-height: 25px;
}

.numpad-component .numpad-message > span {
    animation: fadeFromAbove 300ms ease-in-out;
}

@keyframes fadeFromAbove {
    from { 
        opacity: 0;
        transform: translateY(-5px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}