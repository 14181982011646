:root {
	--haven-red: rgb(238, 32, 35);
	--haven-darkred: rgb(214, 24, 31);
	--default-blue: #2196f3;
	--default-darkblue: #0d89ec;
	--rx-green: #8bbd34;
	--rx-darkgreen: #235921;

	--main-color: var(--haven-red);
	--main-color-shaded: var(--haven-darkred);
	--secondary-color: var(--default-blue);
	--secondary-color-shaded: var(--default-darkblue);
	--panel-color: rgb(0, 0, 0);
}

@keyframes fadeInDown {
	0% {
		opacity: 0;
		transform: translateY(-15px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.secondary-btn {
	background-color: var(--secondary-color) !important;
	border-color: var(--secondary-color) !important;
}

.secondary-btn:hover {
	background-color: var(--secondary-color-shaded) !important;
	border-color: var(--secondary-color-shaded) !important;
}

.ant-btn.light-btn {
	color: var(--secondary-color) !important;
	background-color: white !important;
	border-color: var(--secondary-color) !important;
}

.ant-btn.light-btn:hover {
	color: white !important;
	background-color: var(--secondary-color) !important;
	border-color: white !important;
}

.create-crate-table-component .p-datatable .p-datatable-tbody > tr:not(.p-selectable-row) {
	color: #495057;
}
