.ant-btn.primary-btn {
    padding: 6px 30px;
    background-color: #ee2023;
    color: #fff;
    border: none;
}
  
.ant-btn.colorless-btn {
    padding: 6px 30px;
    background-color: transparent;
}
  
.ant-btn.borderless-btn {
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: transparent;
    font-weight: 600;
    border: none;
}