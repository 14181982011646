/** @format */
@font-face {
	font-family: 'pt-sans';
	src: url("./assets/fonts/PTSans-Regular.ttf");
}

@font-face {
	font-family: 'pt-sans-bold';
	src: url("./assets/fonts/PTSans-Bold.ttf");
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1, h2, h3 {
	font-family: 'pt-sans-bold';
}

.no-margin {
	margin: 0;
}