#footer-component {
  position: relative;
}

#footer-component .ant-layout-footer {
  /* position: absolute; */
  bottom: 0;
  text-align: center;
  width: 100vw;
  background-color: black !important;
  color: white !important;
  padding: 12px !important;
}

.footer-company {
  cursor: pointer;
  transition: color 400ms ease-in-out;
}

.footer-company:hover {
  color: darkred;
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
}

.footer-copyright-text {
  grid-column-start: 2;
}

.footer-version-text {
  text-align: right;
}
