#crate-component {
    --title-font-size: 16px;
    --title-vertical-offset: -16px;
    --title-color: rgb(29, 29, 29);
    --input-background-color: transparent;
    --input-disabled-background-color: transparent;
    --input-border: none;
    --input-height: 50px;
    --input-font-size: 18px;
    --input-color: green;
    --input-disabled-color: rgb(120, 120, 120);
    --placeholder-color: rgb(116, 116, 116);
}

.crates {
    border-radius: 5px;
    padding: 5px 15px;
    margin: 15px 0;
}

/* ITEM COLUMNS */
.crate-item {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 25px;
    /* animation: fadeInDown 300ms ease-in-out; */
}

.crate-item.crate-item-grid6 {
    grid-template-columns: repeat(6, 1fr);
    margin-bottom: 1px;
}

.crate-item.item-0 {
    animation: none;
}

.item-total {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 24px;
}

#crate-component .item-total > .ant-form-item {
    grid-column-start: 5;
}

/* ADD ITEM BUTTON */
.add-item-button {
    width: 100%;
    height: 100%;
    margin-top: 10px;
    background-color: rgb(36, 36, 36) !important;
    color: white;
    border: none;
    font-size: 20px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 500;
}

.add-item-button .anticon {
    font-size: 24px;
    position: relative;
    top: -2px;
}

.add-item-button:hover,
.add-item-button:active,
.add-item-button:focus {
    color: var(--haven-red);
}

.delete-item-btn {
    border: none;
}

.delete-item-btn-icon {
    font-size: 25px !important;
    position: relative;
    top: 2px;
}

.delete-item-btn.ant-btn:focus,
.delete-item-btn.ant-btn:hover {
    color: var(--haven-red) !important;
}

/* PLACEHOLDER CUSTOM ANTD STYLE */
#crate-component .ant-input,
#crate-component .ant-select-selector,
#crate-component .ant-input-number-input,
#crate-component .ant-input-number {
    background-color: var(--input-background-color);
    border: var(--input-border);
    height: var(--input-height);
    font-size: var(--input-font-size);
    width: 100%;
}

#crate-component .ant-form-item {
    margin-bottom: 0;
}

#crate-component .ant-form-item .ant-select,
#crate-component .ant-form-item .ant-cascader-picker {
    min-width: 150px;
}

#crate-component
    .ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    height: var(--input-height);
}

#crate-component .ant-select-single .ant-select-selector .ant-select-selection-item,
#crate-component .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: var(--input-height);
}

/* CUSTOMIZE PLACEHOLDERS */
#crate-component .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: var(--input-height);
    color: var(--placeholder-color);
}

#crate-component .ant-input-number-input::placeholder,
#crate-component .ant-input::placeholder {
    color: var(--placeholder-color);
}

/* Ant-Space */
#crate-component .ant-space.full-width,
#crate-component .ant-space.full-width .ant-space-item {
    width: 100%;
}

#crate-component .ant-form label {
    white-space: nowrap;
}

#crate-component .ant-form-item-explain-error {
    position: relative;
    top: -10px;
}

#crate-component .hidden {
    display: none !important;
}

#crate-component .ant-form-item-explain-error {
    border-top: 1px solid #ff4d4f;
}

#crate-component .ant-btn.primary-btn {
    font-size: 18px;
    height: 100%;
    text-transform: uppercase;
}

#crate-component .p-input-icon-left > .p-inputtext {
    height: 47px;
}

@media screen and (max-width: 1250px) {
    .p-button .p-button-icon-left {
        margin-right: 0px !important;
    }

    .create-crate-table-component .pi-filter-slash:before {
        left: 0px !important;
    }

    .create-crate-table-component .p-button-label,
    .create-crate-table-component .button-text {
        display: none !important;
    }

    .production-header .button-text,
    .create-crate-table-component .p-button.p-button-lg {
        min-height: 47px;
    }

    .production-header .button-text,
    .create-crate-table-component .p-button.p-button-lg {
        display: flex;
        justify-content: center;
    }
}

.crateitemline-control__button {
    padding: 0.2rem 0.5rem !important;
}

.crateitemline-control__container {
    display: flex;
    /* align-items: flex-end; */
    justify-content: end;
    /* margin-bottom: 0.2rem; */
}
