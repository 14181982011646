/* CONFIGURABLE VARIABLES 
    --title-font-size,
    --title-font-weight,
    --title-color,
    --title-padding,
    --title-vertical-offset,
    --input-color,
    --input-background-color,
    --input-disabled-color
    --input-disabled-background-color
*/

.input-wrapper {
    background-color: var(--placeholder-background-color, rgba(240, 240, 240, 1));
    margin-bottom: 10px;
}

.input-wrapper .ant-form-item {
    margin-bottom: 0;
}

.input-wrapper .input-wrapper-title.title-animation {
    /* animation: 200ms ease-out 0s 1 appear; */
}

.input-wrapper .input-wrapper-title,
.input-wrapper .input-wrapper-invisible-title {
    position: relative;
    margin-top: 15px;
}

.input-wrapper .input-wrapper-title > span {
    position: absolute;
    width: max-content;
    top: var(--title-vertical-offset, -3px);
    left: 3px;
    font-size: var(--title-font-size, 14px);
    font-weight: var(--title-font-weight, 350);
    color: var(--title-color, gray);
    padding: var(--title-padding, 0 3px);
    background: linear-gradient(0deg, rgba(240, 240, 240, 1) 10%, rgba(255, 255, 255, 1) 50%);
    z-index: 10;
}

.input-wrapper .input-wrapper-title.invisible-title {
    opacity: 0;
    transition: opacity 150ms ease-in;
}

.input-wrapper .ant-input,
.input-wrapper .ant-input-number-input,
.input-wrapper .ant-select {
    color: var(--input-color, gray) !important;
    background-color: var(--input-background-color, rgba(240, 240, 240, 1)) !important;
}

.input-wrapper .ant-input[disabled] {
    color: var(--input-disabled-color, gray) !important;
    background-color: var(--input-disabled-background-color, rgba(240, 240, 240, 1)) !important;
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: translateY(5px);
    }
    100% {
        opacity: 100;
        transform: translateY(0);
    }
}
