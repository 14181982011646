.numpad-modal-container.ant-modal {
    padding: 0;
    width: 100% !important;
    height: 100% !important;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
}

.numpad-modal-container .ant-modal-content {
    width: min(75vh, 50vw);
    min-width: 300px;
    max-width: 500px;
}

.numpad-modal {
    max-height: 90vh;
    min-height: 70vh;
    height: 150vw;
}

.numpad-modal > div {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
