.ship-crate-dialog .p-dialog-header {
    background: url('../../assets/images/ModalHeader.jpg') !important;
    background-position: center !important;
    background-size: cover !important;
}

.ship-crate-dialog .p-dialog-header h1 {
    color: white;
}

.ship-crate-dialog .anticon.anticon-check.ant-steps-finish-icon {
    vertical-align: 0.075em !important;
}

.error-message__wrapper {
    padding: 0.4rem;
    border-left: 9px solid #ff6600;
    background-color: #ffc199;
    width: fit-content;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    white-space: pre-line;
}
