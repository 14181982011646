.print-label-component {
    --label-height: min(65vh, 60vw);
}

.print-label-component .label-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.print-label-component .label-image {
    position: relative;
    height: var(--label-height);
    margin-bottom: 15px;
    width: 100%;
}

.print-label-component .label-image img {
    border: 1px solid darkgray;
    transform: translateY(-15%) rotate(90deg);
     transform-origin: 50% 50%;
    max-width: var(--label-height);
}

.print-label-component .button-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.print-label-component .ant-btn,
.print-label-component .ant-btn-primary {
    font-size: 22px;
    text-transform: uppercase;
    padding: 0 25px;
    height: 50px;
}

.change-address-button{
    margin-bottom: 10px;
}

.render-Address-Container{
    align-items: center;
    justify-content: center;
    text-align: center;
}