#sidebar {
	--panel-color: var(--panel-color);
	--selected-color: var(--main-color);
}

#sidebar .ant-layout-sider-trigger:hover {
	color: var(--selected-color);
}

#sidebar {
	background-color: black;
}

#sidebar .ant-menu.ant-menu-dark,
#sidebar .ant-layout-sider-trigger {
	background-color: black;
}

#sidebar .ant-menu-item {
	height: 80px;
	max-width: 190px;
	display: flex;
	align-items: center;
	margin: 10px 5px;
	border-radius: 10px;
}

#sidebar .ant-menu-item.ant-menu-item-selected {
	background-color: var(--selected-color);
	animation: change-background 200ms ease-in-out;
}

#sidebar .ant-menu-title-content {
	font-family: pt-sans;
	font-size: 20px;
	margin-left: 43px;
	position: relative;
	top: -1px;
}

#sidebar .anticon.sidebar-icon.ant-menu-item-icon {
	line-height: 35px;
}

#sidebar.ant-layout-sider-collapsed .ant-menu-item:not(.ant-menu-item-selected) {
	background-color: rgb(40, 40, 40);
}

.site-layout .site-layout-background {
	background: #fff;
}

.sidebar-icon {
	position: absolute !important;
}

@keyframes change-background {
	from { background-color: rgb(40, 40, 40); }
	to { background-color: var(--selected-color); }
}