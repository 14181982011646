.edit-inventory-component {
    .ant-input-number {
        width: 100%;
    }

    .ant-form-item-label {
        padding: 0 0 2px;
    }

    .ant-form-item-label > label {
        font-size: 15px;
    }

    .ant-modal-title {
        font-size: 22px;
    }
}