/* Basic */
.bulky-checkbox .ant-checkbox-inner {
    background-color: rgb(195, 195, 195);
    border-color: transparent;
    color: black;
    width: 20px;
    height: 20px;
}

.bulky-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: black;
}

.bulky-checkbox .ant-checkbox + span {
    font-size: 12px;
    color: rgb(175, 175, 175);
    align-self: center;
}

.bulky-checkbox .ant-checkbox-checked + span {
    color: black !important;
}

.bulky-checkbox .ant-checkbox-inner::after {
    top: 42%;
    left: 20%;
    width: 6px;
    height: 13px;
}

/* Add-on: "checkbox-title" */
.bulky-checkbox.checkbox-title .ant-checkbox + span {
    font-size: 15px;
}
