.Nav Nav.Link {
	font-size: 35px;
}

.header-avatar {
	background-color: var(--main-color);
	margin-right: 20px;
	cursor: pointer;
	font-size: 1.5rem !important;
}

@media print {
	.Nav Nav.Link {
		display: "none";
	}
}

.DivisionSelect>.ant-select-item-option-content {
	padding: 20px;
  }
  .DivisionSelect>.ant-select-item {
	min-height: 100px !important;
  }
  
  .DivisionSelect>.ant-select-dropdown {
	z-index: 30000 !important;
  }
  
  .DivisionSelect>.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	height: 60px !important;
  }
