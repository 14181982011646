.p-button.p-button-lg.ship-button,
.p-button.p-button.lg.ship-button:hover {
	border-color: var(--main-color);
	background-color: var(--main-color);
}

.p-button:enabled.ship-button:hover, 
.p-button:not(button):not(a):not(.p-disabled).ship-button:hover {
	border-color: var(--main-color-shaded);
	background-color: var(--main-color-shaded);
}

.inventory-component .p-component {
	padding: 1rem;
}

#finishedgoodstable tr.p-rowgroup-header td {
	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
}

#finishedgoodstable .p-inputnumber-input {
	width: 100%;
}

.ship-crate-dialog .ant-form-item-label > label {
	font-size: 1.6em;
}

.ship-crate-dialog .ant-steps-item-title {
	font-size: 24px;
	line-height: 30px;
}

.ship-crate-dialog .p-dialog-header-close-icon.pi.pi-times {
	font-size: 2rem;
}

.ship-crate-dialog .p-dialog-header-icon.p-dialog-header-close.p-link {
	height: 3rem;
	width: 3rem;
	border: 3px solid white;
}

/* TABLE BUTTONS */
.p-button.p-button-squared {
	height: 2.357rem;
	border-radius: 10%;
}

/* SHIP & BOL BUTTONS */
.ship-button > div {
	display: flex;
	align-items: center;
}

.ship-button span,
.lading-button .p-button-label {
	text-transform: uppercase;
	font-weight: 500;
	font-size: 18px;
}

@media screen and (max-width: 1250px) {
	.ship-button, .lading-button {
		height: 47px;
		display: flex;
		justify-content: center;
	}

	.ship-button span,
	.lading-button .p-button-label {
		display: none;
	}

	.ship-button .me-2,
	.lading-button .p-button-icon {
		margin-right: 0 !important;
	}
}


.reportMenu{
	font-size: 3rem;
}