.create-crate-table-component {
    height: 100%;

    .p-datatable-flex-scrollable .p-datatable-wrapper {
        overflow-y: hidden;
    }

    .p-datatable-table,
    .p-datatable-tbody {
        height: 100% !important;
    }

    .p-datatable {
        padding: 1em 0.5em 0.5em 1em;
    }

    .p-paginator {
        padding: 0.2em;
    }
    
    .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td,
    .p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
        padding: 1rem 1rem;
    }

    .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td:first-child,
    .p-datatable.p-datatable-lg .p-datatable-thead > tr > th:first-child {
        min-width: 70px;
    }

    .p-datatable.p-datatable-lg .p-datatable-thead > tr > th:not(:first-child),
    .p-datatable.p-datatable-lg .p-datatable-tbody > tr > td:not(:first-child) {
        padding-left: 1rem;
    }

    .p-column-filter-menu-button.p-column-filter-menu-button-active, 
    .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
        background: #1890ff;
        color: white;
    }
}

.production-header {
    .p-button:enabled.create-button:hover,
    .p-button:not(button):not(a):not(.p-disabled) .primary-haven:hover {
        background-color: var(--main-color-shaded);
        border-color: var(--main-color-shaded);
    }

    button {
        margin-left: 15px;
        width: 150px;
    }

    .table-name {
        font-weight: 500;
        margin-bottom: 0;
        color: gray;
    }

    .create-button {
        background-color: var(--main-color);
        border-color: var(--main-color);
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .button-icon {
        font-size: 25px;
        position: relative;
        top: 2px;
    }

    .p-disabled,
    .p-disabled:disabled {
        opacity: 1;
        background-color: rgba(180, 180, 180, 0.5);
        color: #E9ECEF;
        border-color: #E9ECEF;
        cursor: progress;
    }
}

/* OVERRIDES */
.production-header .button-text,
.create-crate-table-component .p-button.p-button-lg {
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
}

.create-crate-table-component {
    .pi-filter-slash:before {
        position: relative;
        left: 10px;
    }

    .p-datatable .p-datatable-header {
        border-top: none;
    }

    .p-datatable.p-datatable-lg .p-datatable-thead>tr>th:first-child,
    .p-datatable.p-datatable-lg .p-datatable-tbody>tr>td:first-child {
        max-width: 3em;
    }

    .p-datatable.p-datatable-lg .p-datatable-thead>tr>th:first-child .p-checkbox {
        display: none;
        pointer-events: none;
    }

    .p-datatable .p-datatable-tbody>tr:not(.p-selectable-row) {
        color: gainsboro;
    }

    .p-datatable .p-datatable-tbody>tr:not(.p-selectable-row) .p-checkbox {
        display: none;
    }
}

/* SWTICH OVERRIDE */
.production-header {
    .ant-switch {
        height: 50px;
        min-width: 140px;
    }

    .ant-switch-inner {
        font-size: 16px;
        font-weight: 500;
        padding-left: 20px;
    }

    .ant-switch-handle {
        top: 5px;
        left: 4px;
        height: 40px;
        width: 40px;
    }

    .ant-switch-handle:before {
        border-radius: 100px;
    }

    .ant-switch-checked .ant-switch-handle {
        left: calc(100% - 44px);
    }

    .ant-switch-checked .ant-switch-inner {
        padding-right: 40px;
    }
}

.mo {
    width: 10% !important
}

.customer_code {
    width: 10% !important
}

.quantity {
    width: 10% !important
}

.so {
    width: 10% !important
}

.part_no {
    width: 10% !important
}

.boxStatusHeader{
   text-align: center !important;
   padding-left: 10% !important
}

.progressHeader{
    text-align: center !important;
    padding-left: 7% !important
}