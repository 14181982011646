@media print {
    @page {
        margin: 1cm 1cm;
    }
    .printme {
        padding: 0 !important;
        margin: 0 !important;
        font-size: 12px !important;
    }

    .print-display-none {
        display: none !important;
    }

    .print-display-only {
        display: block !important;
    }

    .highlight-input {
        box-shadow: none;
    }
}

.print-display-only {
    display: none;
}

.printme {
    padding: 0.6cm 1cm;
    font-size: 24px;
    border: 1px solid black;
    color: black !important;
}
.printme p,
.printme h6 {
    margin: 0;
}

.printme.report-fingoods {
    font-size: 16px;
}

.bol-wrapper .printme * {
    margin: 0;
}

.printme hr {
    border: 1px solid black !important;
    margin: 3px 0 !important;
    opacity: 1;
}

.printme .p-sm {
    font-size: 7px;
    line-height: 1em;
}

.printme .flex-column {
    display: flex;
    flex-direction: column;
}

.printme .border {
    border: 1px solid black !important;
}

.printme .underline {
    width: 100%;
    height: 1.4em;
    border-bottom: 1px solid black !important;
}

.printme .checkbox {
    width: 1.2em;
    height: 1.2em;
    border: 1px solid black;
    margin-left: 1em;
}

.break-inside-avoid {
    break-inside: avoid;
}

/*--- Bill of lading ---*/
.bol-wrapper label {
    margin-right: 1em;
}

.bol-wrapper table {
    width: 100%;
    border-top: 2px solid black;
    font-size: 0.9em;
}

.bol-wrapper table th {
    text-align: center;
    line-height: 1em;
}

.bol-wrapper table td:not(.total) {
    text-align: right;
    line-height: 1.2em;
    border-bottom: 1px solid black;
}

.bol-wrapper tbody::after {
    content: '';
    display: block;
    height: 2em;
}

.bol-checkbox.p-checkbox-checked .p-checkbox-box {
    border-color: black !important;
    background: white !important;
    border-width: 1px !important;
}

.bol-checkbox.p-checkbox-checked .p-checkbox-icon {
    color: black !important;
}

.bol-wrapper table .bol-inputnumber .p-inputtext {
    width: 100%;
}

/*--- Finished Goods ---*/
.fingoods-wrapper table {
    width: 100%;
}

.fingoods-wrapper table thead {
    border-bottom: 2px solid black;
}

.fingoods-wrapper table th,
.fingoods-wrapper table td {
    text-align: center;
}

.fingoods-wrapper h4 {
    margin: 0;
}

.fingoods-wrapper .fingoods-header th {
    font-weight: 400;
}

.fingoods-wrapper .total {
    font-size: 1.4em;
    font-weight: 700;
    line-height: 1.1;
}

.fingoods-wrapper .subtotal {
    font-size: 1.2em;
    font-weight: 700;
    line-height: 1.1;
}

.fingoods-wrapper .customer-subheader {
    line-height: 1.1;
    padding-left: 2.4rem;
    text-align: left;
}

.fingoods-wrapper td.customer-subheader {
    font-size: 1.4em;
    font-weight: 700;
}

/*--- Packing List Report ---*/

.packinglist-wrapper {
    /* border: 5px solid red; */
    /* min-height: 100vh;
  display: flex;
  flex-direction: column; */
}

.packinglist-wrapper .table-header {
    text-decoration: underline;
}

.packinglist-wrapper .packing-list-info {
    padding: 0.6rem;
    border: solid 2px black;
    box-shadow: 5px 5px;
    margin: 2rem 0;
}

.packinglist-wrapper .packing-list-table-container {
    padding: 1rem;
    margin: 2rem 0;
    width: 100%;
}

.packinglist-wrapper .packing-list-table {
    border: none;
    width: 100%;
}

.packinglist-wrapper .packing-list-footer {
    margin: 3rem 0;
}

.packinglist-wrapper .packing-list-title {
    text-align: center;
    font-weight: bold;
    font-style: italic;
    margin: 1rem 0 2rem;
    font-size: 2rem;
}

.packinglist-wrapper .packing-list-generated {
    margin: 1rem 0;
    font-size: 0.8rem;
}

.packinglist-wrapper .table-quantity-header {
    text-align: center;
    border-bottom: solid 1px black;
}

/********************************************/

.no-drag {
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    user-select: none;
}

.highlight-input {
    box-shadow: 0px 0px 10px 2px #2196f3;
}

.bol-errormessage .ant-divider {
    color: rgba(255, 0, 0, 0.8);
    border-top-color: rgba(255, 0, 0, 0.3);
    margin: 0.1rem 0;
}

.bol-errormessage span:not(.ant-divider-inner-text) {
    font-size: 20px;
}
.bol-errormessage td {
    padding: 0.2rem 0;
}
