.quantity-progress {
    display: flex;
    flex-direction: column;
    width: 250px;
    align-items: center;

    .progress-text {
        display: flex;
        flex-direction: column;
        font-size: 13px;
    }

    .progress-text span:not(:first-child) {
        padding-left: 5px;
    }

    .ant-progress-bg,
    .ant-progress-success-bg {
        height: 12px !important;
    }
}
