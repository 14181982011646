#reset-password .login-title {
    color: black;
}

#reset-password .ant-form-vertical,
#reset-password .ant-form-item-label {
    padding: 0;
}

#reset-password .button-wrapper {
    margin-top: 35px;
}

#reset-password .ant-spin-dot-item {
    background-color: red;
    width: 15px;
    height: 15px;
}

#reset-password .error-container {
    padding: 0 40px;
}

#reset-password .error-content {
    margin-bottom: 20px;
}

#reset-password .error-symbol {
    font-size: 50px;
    margin-bottom: 10px;
}