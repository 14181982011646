/* CONFIGURABLE VARIABLES 
    --input-height
    --placeholder-color, 
    --pallet-selection-color, 
    --pallet-selection-font-weight, 
*/

.pallet-size-input-container {
    height: var(--input-height, 50px);
    width: fit-content;
    display: flex;
    flex-direction: row;
    gap: 40px;
    border-radius: 2px;
    padding: 20px 20px 20px 15px;
    color: var(--placeholder-color, rgb(175, 175, 175));
    white-space: nowrap;
}

.pallet-size-input-container .type-label {
    font-size: var(--input-font-size, 18px);
    margin-right: 15px;
}

.pallet-size-input-container .type-label.current-type {
    color: var(--pallet-selection-color, black);
    font-weight: var(--pallet-selection-font-weight, 500);;
}

