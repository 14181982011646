#request-reset-form {
    width: calc(240px + 10vw);
    max-width: 420px
}

#request-reset-form .ant-input {
    max-width: 360px;
    text-align: center !important;
}

#request-reset-form .ant-form-item-explain {
    text-align: center !important;
}

#request-reset-form .login-title {
    color: black;
    text-align: center;
    margin-bottom: 2px;
}