.shipment-component .p-component {
    padding: 1rem;
}

.shipment-component .p-button-label {
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
}

@media screen and (max-width: 1100px) {
    .shipment-component .p-button-label {
        display: none;
    }
}