.greenBoxLabel {
  fill: green !important;
  width: 2vw !important;
  /* width: 3% !important; 
    height: 3% !important */
  /* width: 100px !important; */
}

.yellowBoxLabel {
  fill: yellow !important;
  width: 2vw !important;
  /*  width: 1.5% !important; 
    height: 1.5% !important */
  /* width: 100px !important;  */
}

.redBoxLabel {
  fill: red !important;
  width: 2vw !important;
  /* width: 3% !important; 
    height: 3% !important */
  /* width: 100px !important;  */
}

.blueBoxLabel {
  fill: blue !important;
  width: 2vw !important;
  /* width: 3% !important; 
    height: 3% !important */
  /* width: 100px !important;  */
}

.orangeBoxLabel {
  fill: orange !important;
  width: 2vw !important;
  /* width: 3% !important; 
    height: 3% !important */
  /* width: 100px !important;  */
}

.BoxLabel {
  width: 2vw !important;
}

.ProgressDiagramFlexCollection {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  row-gap: 30%;
  column-gap: 20%;
  flex-wrap: wrap;
}
